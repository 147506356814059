import { graphql, navigate } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Button from '../components/Button'
import CenterContainer from '../components/CenterContainer'
import MonoTitle from '../components/MonoTitle'
import SEO from '../components/SEO'
import SmidgeDownContainer from '../components/SmidgeDownContainer'

// credits to Jake Jarvis https://codepen.io/jakejarvis/pen/pBZWZw?editors=0110
export const WaveAnimation = styled.span`
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
  margin-right: 5px;

  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(14deg);
    }
    20% {
      transform: rotate(-8deg);
    }
    30% {
      transform: rotate(14deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <>
      <SEO title="Page not found" />
      <CenterContainer>
        <SmidgeDownContainer>
          <MonoTitle>404 - there's nothing here buddy</MonoTitle>
          <Button onClick={() => navigate('/')} highlight>
            <WaveAnimation>👋</WaveAnimation> go to main page
          </Button>
        </SmidgeDownContainer>
      </CenterContainer>
    </>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
